import { AskRequest, SummaryItem } from '@hone-automation/common';
import Queue from '../Queue';
import './Dashboard.scss';
import { prefixClass } from 'lib/utils';
import { DashboardCardProps } from './DashboardCard/DashboardCard';
import { DashboardCardCountType, DashboardCardType } from './Types';
import { IconNbAp, IconNbAsk, IconSmartStar } from 'components/Icons';
import DashboardCardList from './DashboardListGroup/DashboardCardList';
import DashboardChart from 'presentation/pages/dashboard/components/DashboardChart/DashboardChart';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type DashboardProps = {
  meId: string;
  requests: AskRequest[];
  chartData: SummaryItem[];
};

const Dashboard = ({ meId, requests, chartData }: DashboardProps) => {
  const prefix = prefixClass('dashboard');
  const { locationId } = useParams();
  const navigateTo = useNavigate();
  const requestsInprogress = useMemo(() => {
    if (!requests) return 0;
    return requests.filter(request => request.status === 'In Progress').length;
  }, [requests]);

  const requestsOpen = useMemo(() => {
    if (!requests) return 0;
    return requests.filter(request => request.status === 'Open').length;
  }, [requests]);

  const dashboardCards: DashboardCardProps[] = [
    {
      type: 'disabled' as DashboardCardType,
      icon: (<IconSmartStar />) as React.ReactNode,
      title: 'Opportunities' as string,
      badgesContainer: 'comming soon' as string,
      onClick: () => {},
    },
    {
      type: 'normal' as DashboardCardType,
      icon: (<IconNbAsk />) as React.ReactNode,
      title: 'Queue' as string,
      badgesContainer: [
        {
          type: 'highlight' as DashboardCardCountType,
          value: (requestsInprogress || 0) as number,
        },
        {
          type: 'normal' as DashboardCardCountType,
          value: (requestsOpen || 0) as number,
        },
      ],
      onClick: () => {},
    },
    {
      type: 'highlight' as DashboardCardType,
      icon: (<IconNbAp />) as React.ReactNode,
      title: 'Payables' as string,
      badgesContainer: [
        {
          type: 'highlight' as DashboardCardCountType,
          value: 1 as number,
        },
        {
          type: 'normal' as DashboardCardCountType,
          value: 2 as number,
        },
      ],
      onClick: () => {
        navigateTo(`/app/location/${locationId}/report/ap-aging`);
      },
    },
  ];

  return (
    <div className={prefix()}>
      <div className={prefix('left-pane')}>
        <div className={prefix('left-pane__chart-container')}>
          {/* <DashboardTitleTabs tabs={tabs} activeTab="overview" onTabChange={onTabChange} /> */}
          <DashboardChart
            chartItems={chartData}
            type={'bar'}
            showLegend={true}
            legendPosition="bottom"
            bmSelected={'Cost of Goods Sold'}
            xAxis2nd="WEEK ENDING DATE"
          />
        </div>
        <div className={prefix('left-pane__cards-container')}>
          <DashboardCardList cards={dashboardCards} />
        </div>
      </div>
      <div className={prefix('right-pane')}>
        <div className={prefix('right-pane__title')}>QUEUE</div>
        <Queue requests={requests} meId={meId} />
      </div>
    </div>
  );
};

export default Dashboard;
