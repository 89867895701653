import { AppLayout } from 'presentation/components';

import DashboardViewNew from 'presentation/pages/dashboard/DashboardViewNew';

export const NewDashboardViewFactory = (): JSX.Element => {
  return (
    <AppLayout noFooter={true}>
      <DashboardViewNew />
    </AppLayout>
  );
};
