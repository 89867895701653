import { useMemo } from 'react';
import { formatWhisperTooltip, WhisperTooltip } from './formatReportValuesUtils';
import { FormatActual, formatAmount, formatValue } from '../ValueFormatters/ValueFormatters';
import { Whisper, Tooltip } from 'rsuite';
import { Amounts, FlaggedCellProps } from 'presentation/components/KSExport/reportNTParser/types';

export const useReportValueFormatters = ({
  cellData,
  smoothing,
  showAnomaly,
  showtrxAnomaly,
  composedAmount,
  flaggedCell,
  flagInfo,
  filters,
}: {
  cellData: Amounts;
  smoothing: boolean;
  showAnomaly: boolean;
  showtrxAnomaly: boolean;
  composedAmount: number | null;
  flaggedCell: string | null;
  flagInfo: {
    flaggedTransactions: FlaggedCellProps[];
    isFlaggedCell: boolean | '' | null;
    fTrxtotal: number;
    totalTrxFlagged: number;
  };
  filters: any;
}) => {
  const { transactions, amount, amountAVG, varianceAnalisys, vizHelpers, anomaly, trxAnomaly, dataRows } = cellData;

  const formatters = useMemo(
    () => ({
      // variable tooltip for actuals
      formatVariableActualTooltip: () => {
        const tooltipData: WhisperTooltip[] = [];
        const trxFlagged = cellData.trxFlagged;
        const flaggedTransactions = flaggedCell && trxFlagged.some(trx => trx.type === 'trx');
        const isFlaggedCell = flaggedCell && trxFlagged.some(trx => trx.type === 'Flagged Cell');
        const deviation = amount !== null ? amount - anomaly.stats?.mean : 0;
        const mustSmooth = smoothing && !vizHelpers.noValuesToShow && amountAVG !== undefined && amountAVG !== amount;

        // Flagged Cell info
        if (flagInfo.isFlaggedCell) {
          tooltipData.push(
            {
              title: 'Flagged Cell',
              value: formatAmount(trxFlagged[0].amount),
              style: {
                textTransform: 'uppercase',
                fontSize: '10px',
                textAlign: 'center',
                paddingTop: '5px',
                fontWeight: 'bold',
                color: flaggedCell === 'open' ? 'var(--ks-ASK-customer-text)' : 'var(--ks-ASK-resolved-text)',
              },
              valueStyle: { fontSize: '11px' },
            }
            /* { title: 'Value', value: formatAmount(trxFlagged[0].amount), style: { paddingBottom: '5px' } } */
          );
        }
        // Smoothing info
        if (mustSmooth) {
          tooltipData.push({
            title: 'Original',
            value: formatAmount(amount),
            style: { paddingBottom: '5px', marginTop: '5px', borderBottom: '1px solid #444' },
            valueStyle: { color: 'var(--ks-smoothing-color-text)' },
          });
        }
        // Transactions info
        if (transactions && transactions > 0) {
          // Only display the amount of transactions if there are more than one transaction
          if (transactions > 0) {
            // Review all transactions & calculate total
            const trxtotal = dataRows.reduce((total, row) => total + row.amount, 0);
            // Add total if there is moore than one transaction
            tooltipData.push({
              title: `${transactions} transaction${transactions && transactions > 1 ? 's' : ''}`,
              value: formatAmount(trxtotal),
              style: { marginTop: '5px' },
            });
          }
          if (flagInfo.flaggedTransactions) {
            const fTrxtotal = flagInfo.fTrxtotal;
            const totalTrxFlagged = flagInfo.totalTrxFlagged;
            if (totalTrxFlagged > 0) {
              tooltipData.push({
                title: `${totalTrxFlagged} Flagged trx${trxFlagged && totalTrxFlagged > 1 ? 's' : ''}`,
                value: formatAmount(fTrxtotal),
                style: {
                  textTransform: 'uppercase',
                  fontSize: '10px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: flaggedCell === 'open' ? 'var(--ks-ASK-customer-text)' : 'var(--ks-ASK-resolved-text)',
                },
                valueStyle: { fontSize: '11px' },
              });
            }
          }
        }
        // Click Message
        tooltipData.push({
          title:
            amount && transactions && transactions > 0
              ? 'Click to flag or review transactions'
              : flaggedCell && !flagInfo.flaggedTransactions
                ? 'Click to review the Flagged item'
                : amount
                  ? 'Click to flag this Value'
                  : 'Click to flag this cell',
          value: '',
          style: { opacity: 0.7, width: '100%', marginBottom: '5px', fontStyle: 'italic' },
        });
        // Average info
        if (amount) {
          tooltipData.push({
            title: `AV ${formatAmount(anomaly.stats?.mean)}`,
            value: `𝚫 ${formatAmount(deviation)}`,
            style: {
              opacity: 0.8,
              width: '100%',
              textTransform: 'uppercase',
              fontSize: '10px',
              fontWeight: 'bold',
              marginBottom: '5px',
              paddingTop: '5px',
              borderTop: '1px solid #444',
            },
            valueStyle: {
              color:
                deviation > 0
                  ? 'var(--ks-anomaly-positive-contrast-color)'
                  : 'var(--ks-anomaly-negative-contrast-color)',
            },
          });
        }
        return tooltipData;
      },
      // Formatters will be added here
      formatComposedAmount: (
        budget: number | null = null,
        small: boolean = false,
        cb?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
      ) => {
        // Let's get the tooltip for the actuals
        const formatVariableActualTooltip = budget ? [] : formatters.formatVariableActualTooltip();
        if (formatVariableActualTooltip.length > 0) {
          const tooltip = formatWhisperTooltip(formatVariableActualTooltip);
          return (
            <Whisper placement="right" trigger="hover" delayOpen={600} speaker={<Tooltip>{tooltip}</Tooltip>}>
              {FormatActual(budget ? budget : composedAmount, vizHelpers.isTotal, small, cb)}
            </Whisper>
          );
        }

        return FormatActual(budget ? budget : composedAmount, vizHelpers.isTotal, small, cb);
      },

      formatVariance: (children: React.ReactElement) => {
        // stats info
        if (varianceAnalisys && varianceAnalisys.tendency !== 'neutral') {
          const tooltip =
            amount !== null &&
            formatWhisperTooltip([
              {
                title: varianceAnalisys.category,
                value: varianceAnalisys.tendency,
                style: { padding: '10px 0', fontWeight: 'bold' },
                valueStyle: {
                  color:
                    varianceAnalisys.tendency === 'positive'
                      ? 'var(--ks-anomaly-positive-contrast-color)'
                      : 'var(--ks-anomaly-negative-contrast-color)',
                },
              },
              { title: varianceAnalisys.message, value: '', style: { opacity: 0.7 } },
              {
                title: varianceAnalisys.explanation || '',
                value: '',
                style: { fontSize: '12px', padding: '10px 0', color: 'var(--ks-white)' },
              },
            ]);
          return (
            <Whisper placement="top" trigger="hover" delayOpen={400} speaker={<Tooltip>{tooltip}</Tooltip>}>
              {children}
            </Whisper>
          );
        }
        return children;
      },

      formatActualsBlock: (children: React.ReactElement) => {
        // stats info
        if (showAnomaly) {
          const deviation = amount !== null ? amount - anomaly.stats?.mean : 0;
          const tooltip =
            amount !== null &&
            formatWhisperTooltip([
              { title: 'Average', value: formatAmount(anomaly.stats?.mean) },
              {
                title: 'Deviation',
                value: formatAmount(deviation),
                valueStyle: {
                  color:
                    deviation > 0
                      ? 'var(--ks-anomaly-positive-contrast-color)'
                      : 'var(--ks-anomaly-negative-contrast-color)',
                },
              },
            ]);
          return (
            <Whisper placement="top" trigger="hover" delayOpen={800} speaker={<Tooltip>{tooltip}</Tooltip>}>
              {children}
            </Whisper>
          );
        }
        return children;
      },
    }),
    [filters.smoothing, flagInfo.flaggedTransactions]
  );

  return formatters;
};
