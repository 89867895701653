import React from 'react';
import { useFlaggedTransactions } from 'hooks/flaggedTransactions';
import { AskRequestStatus } from '@hone-automation/common';
import { shallow } from 'zustand/shallow';
import type { PropsType } from './MarkAsSolvedButton';
import { usePatchFlaggedTransactionMutation } from 'hooks/flaggedTransactions/usePatchFlaggedTransactionMutation';
import { useQueueContext } from '../../Queue/QueueContext';

// Define the expected props for the HOC
export type WithAskRequestsProps = {
  txId: string;
  locationId: string;
  activeTab: AskRequestStatus;
  isSideView?: boolean;
};

const withAskRequests = <P extends WithAskRequestsProps>(
  WrappedComponent: ({ onSubmit, isPending }: PropsType) => React.JSX.Element
) => {
  return function WithAskRequests(props: P) {
    const { locationId, activeTab, txId } = props;

    const { dispatch } = useQueueContext();

    const { setAnimateTransaction } = useFlaggedTransactions(
      state => ({
        setAnimateTransaction: state.setAnimatedTransaction,
      }),
      shallow
    );

    const { isPending, mutate } = usePatchFlaggedTransactionMutation(locationId!, activeTab, (trx: any | undefined) => {
      dispatch({ type: 'SET_SELECTED_ITEM', payload: trx });
    });

    const markAsSolved = async ({ comment }: { comment: string }) => {
      setAnimateTransaction('Closed');
      mutate({ txId, status: 'Closed', comment });
    };

    // Extract only the necessary props for the wrapped component
    const wrappedComponentProps = {
      isPending: isPending,
      onSubmit: markAsSolved,
    } as PropsType;

    return <WrappedComponent {...wrappedComponentProps} />;
  };
};

export default withAskRequests;
