import { useMutation } from '@tanstack/react-query';
import { makeRemoteReviewFinancials } from 'main/factories/usecases';
import { FIVE_SECONDS, showToast, TOAST_ERROR, TOAST_SUCCESS } from 'lib/utils';
import { useParams } from 'react-router-dom';

export const useReviewFinancials = () => {
  const { locationId } = useParams();

  return useMutation({
    mutationFn: () => {
      const reviewFinancialsService = makeRemoteReviewFinancials();
      if (!locationId) throw new Error('Location id not found');
      return reviewFinancialsService.reviewFinancials({ locationId });
    },
    onError: e => {
      showToast(e.message, TOAST_ERROR, FIVE_SECONDS);
    },
    onSuccess: response => {
      showToast(`Successfully submitted financials for review`, TOAST_SUCCESS, FIVE_SECONDS);
    },
  });
};
