import classNames from 'classnames';
import { IconChevronDown } from 'components/Icons';
import { prefixClass } from 'lib/utils';
import { useRef, useState, useEffect } from 'react';
import './CustomDropDown.scss';

type CustomDropDownDataProps = {
  label: string;
  value: string;
  icon?: React.ReactNode;
};

const CustomDropDown = ({
  label,
  data,
  selected,
  rightAligned = false,
  onChanged,
}: {
  label: string;
  data: string[] | CustomDropDownDataProps[];
  selected: string;
  rightAligned?: boolean;
  onChanged: (selection: string) => void;
}) => {
  const prefix = prefixClass('custom-dropdown');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const resolveDataInput = (data: string[] | CustomDropDownDataProps[]): CustomDropDownDataProps[] => {
    if (!data || data.length === 0) {
      return [];
    }
    if (typeof data[0] === 'string') {
      return (data as string[]).map(item => ({ label: item, value: item }));
    }
    return data as CustomDropDownDataProps[];
  };

  const [resolvedData, setResolvedData] = useState<CustomDropDownDataProps[]>(resolveDataInput(data));

  const getIndex = (id: string) => {
    return resolvedData.findIndex(item => item.value.toLowerCase() === id.toLowerCase());
  };

  useEffect(() => {
    //console.log('resolvedData', resolvedData);
  }, [resolvedData]);

  const [selectedItem, setSelectedItem] = useState(selected);
  const [selectedIndex, setSelectedIndex] = useState(getIndex(selected));
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    //console.log('selectedItem', selectedItem, selectedIndex, isOpen);
  }, [selectedItem, selectedIndex, isOpen]);

  const handleOpenCloseDropdown = (idSelected?: string) => {
    setIsOpen(!isOpen);
    if (idSelected && idSelected !== selectedItem) {
      setSelectedItem(idSelected);
      setSelectedIndex(getIndex(idSelected));
      onChanged && onChanged(idSelected);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className={prefix()}>
      <div className={prefix('label')}>{label}</div>
      <div className={prefix('dropdown')} onClick={e => handleOpenCloseDropdown()}>
        <div className={prefix('dropdown-selection')}>
          <div className={prefix('dropdown-selection-label')}>
            {selectedIndex > -1 ? resolvedData[selectedIndex].label : ''}
          </div>
          <div className={prefix('dropdown-selection-icon')}>
            <IconChevronDown />
          </div>
        </div>
        <div ref={dropdownRef} className={classNames(prefix('dropdown-options'), { active: isOpen })}>
          {resolvedData.map((item, index) => (
            <div
              key={item.value}
              className={classNames(prefix('dropdown-options-item'), {
                'custom-dropdown-item-selected': index === selectedIndex,
                'right-aligned': rightAligned,
              })}
              onClick={e => handleOpenCloseDropdown(item.value)}
            >
              {item.icon && item.icon}
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomDropDown;
