import { useQuery } from '@tanstack/react-query';
import { makeRemoteAskRequest } from 'main/factories/usecases/ask-requests/remote-get-ask-requests-transactions';
import { AskRequest, AskRequestStatus, FlaggedTransaction } from '@hone-automation/common';
import { transformApiTrxToUITrx, useFlaggedTransactions } from 'hooks/flaggedTransactions';
import { FIVE_SECONDS, TOAST_ERROR, showToast } from 'lib/utils';
import { shallow } from 'zustand/shallow';
import { useHoneLocationUsers } from '../components/HoneLocationUsers';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const getAskRequests = makeRemoteAskRequest();

export const useAskRequestsQuery = (status?: AskRequestStatus) => {
  const { locationId } = useParams();
  const { activeFlaggedTransaction } = useFlaggedTransactions(
    state => ({
      activeFlaggedTransaction: state.activeFlaggedTransaction,
    }),
    shallow
  );
  return useQuery({
    gcTime: 0,
    queryKey: ['askRequests', status, locationId],
    queryFn: async () => {
      const requests = (await getAskRequests.get({ locationId, statuses: status })) as AskRequest[];
      const sortedRequests = requests.sort((a, b) => {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      });

      const trx = sortedRequests.find((trx: any) => trx.id === activeFlaggedTransaction?.id);
      let nextActiveFlaggedTransaction = requests[0] as unknown as FlaggedTransaction;
      if (trx) {
        nextActiveFlaggedTransaction = trx as unknown as FlaggedTransaction;
      }
      useFlaggedTransactions.setState({
        activeFlaggedTransaction: transformApiTrxToUITrx(nextActiveFlaggedTransaction),
      });

      return requests;
    },
  });
};
