import classNames from 'classnames';
import React from 'react';
import './DashboardCard.scss';
import { DashboardCardCount, DashboardCardType } from '../Types';

export type DashboardCardProps = {
  type: DashboardCardType;
  icon: React.ReactNode;
  title: string;
  badgesContainer: string | DashboardCardCount[];
  onClick?: () => void;
};

const DashboardCard = ({ icon, type, title, badgesContainer, onClick }: DashboardCardProps) => {
  return (
    <div
      className={classNames('dashboard-card', {
        highlight: type === 'highlight',
        disabled: type === 'disabled',
      })}
      onClick={onClick}
    >
      <div
        className={classNames('dashboard-card__icon', {
          disabled: type === 'disabled',
        })}
      >
        {icon}
      </div>
      <div
        className={classNames('dashboard-card__title', {
          disabled: type === 'disabled',
        })}
      >
        {title}
      </div>
      <div className="dashboard-card__badges--container">
        {typeof badgesContainer === 'string' ? (
          <div className="dashboard-card__badges--container--title"> {badgesContainer}</div>
        ) : (
          badgesContainer.map((badge, index) => (
            <div
              key={index}
              className={classNames(`dashboard-card__badges--container--item`, {
                highlight: badge.type === 'highlight',
                normal: badge.type === 'normal',
                lowlight: badge.type === 'lowlight',
              })}
            >
              {badge.value}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
