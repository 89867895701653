import React from 'react';
import KSHeader from '../../components/KSHeader';
import { PageHeader } from '../../components';
import { IconNbAsk } from '../../../components/Icons';
import { UIAskRequestsTitles } from '../../../types';
import Queue from '../../components/Queue';
import { useAuthContext } from 'context/useAuthContext';
import { useAskRequestsQuery } from 'hooks/useAskRequestsQuery';

function QueuePage() {
  const { user } = useAuthContext();
  const meId = user?.uid;
  const { data: requests } = useAskRequestsQuery();
  return (
    <div className="report-view">
      <KSHeader />
      <PageHeader icon={<IconNbAsk />} pageTitle={UIAskRequestsTitles.QUEUE} />
      <div className="report-view-container no-padding no-border-radius mobile fixed-width no-scroll">
        <Queue meId={meId!} requests={requests!} />
      </div>
    </div>
  );
}

export default QueuePage;
