import './QueueDetails.scss';
import { prefixClass } from 'lib/utils';

import { useQueueContext } from '../QueueContext';
import TransactionDetails from '../TransactionDetails/TransactionDetails';
import CMComposer from '../CMComposer/CMComposer';
import Chat from '../Chat/Chat';
import TransactionHeader from '../TransactionHeader/TransactionHeader';
import { QueueMarkAsSolvedButton } from '../../Chat/MarkAsSolvedButton';
import { QueueReCategorizeButton } from '../../Chat/RecategorizeButton';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHoneLocationUsers } from 'components/HoneLocationUsers';

const QueueDetails = () => {
  const { isBookkeeperAdmin } = useHoneLocationUsers();
  const prefix = prefixClass('queue-details');

  const { state } = useQueueContext();
  const { selectedItem } = state;

  if (!selectedItem) return null;

  const { transaction } = selectedItem;

  const showMessageComposer =
    selectedItem.status !== 'Closed' || (selectedItem.status === 'Closed' && selectedItem.canReopen);

  return (
    <div className={prefix()}>
      <div className={prefix('header')}>
        <TransactionHeader request={selectedItem} />
      </div>
      <div className={prefix('info')}>
        {selectedItem && <TransactionDetails request={selectedItem} />}
        <div className={prefix('options')}>
          {isBookkeeperAdmin &&
            transaction?.id &&
            selectedItem.status !== 'Closed' &&
            selectedItem?.canRecategorize && (
              <QueueReCategorizeButton
                txId={selectedItem?.id}
                locationId={selectedItem.locationId}
                activeTab={selectedItem.status}
              />
            )}
          {selectedItem.canRecategorize && (
            <QueueMarkAsSolvedButton
              activeTab={selectedItem.status}
              locationId={selectedItem.locationId}
              txId={selectedItem?.id}
              isSideView={false}
            />
          )}
        </div>
      </div>

      <div className={prefix('messages')}>{selectedItem?.comments && <Chat comments={selectedItem.comments} />}</div>
      {showMessageComposer && (
        <div className={prefix('composer')}>
          <CMComposer request={selectedItem} />
        </div>
      )}
    </div>
  );
};

export default QueueDetails;
