import React, { useState } from 'react';

import { AskRequest } from '@hone-automation/common';
import classNames from 'classnames';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';
import { IconPlay } from './Icons';
import TransactionRow from '../presentation/components/AskCustomer/TransactionRow';
import FlaggedCellRow from '../presentation/components/AskCustomer/FlaggedCellRow';
import { RecategorizeButton } from 'presentation/components/Chat/RecategorizeButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHoneLocationUsers } from './HoneLocationUsers';
import MarkAsSolvedButton from '../presentation/components/Chat/MarkAsSolvedButton';

type TransactionDetailsProps = {
  rows: { label: string; value: React.ReactNode }[];
  transaction?: AskRequest;
  isSideView?: boolean;
  toggle?: boolean;
};

function TransactionDetails(props: TransactionDetailsProps) {
  const { isBookkeeperAdmin } = useHoneLocationUsers();

  const { transaction, rows, toggle = false } = props;

  const [miniature, setMiniature] = useState<boolean>(false);

  const statusLocal = () => {
    if ((transaction?.status as any) === 'Categorized') return 'Closed';
    if ((transaction?.status as any) === 'Information Needed') return 'Open';
    return transaction?.status || 'Open';
  };

  const toggleMiniatureExperience = () => {
    setMiniature(value => !value);
  };

  // Intended
  const noop = () => {
    //
  };

  const shouldShowFlagCell = transaction && ['Flagged Cell', 'Customer Request'].includes(transaction.type);

  const MiniatureComponent =
    transaction &&
    (shouldShowFlagCell ? (
      <FlaggedCellRow request={transaction} onClick={noop} />
    ) : (
      <TransactionRow transaction={transaction} onClick={noop} />
    ));

  return (
    <>
      <div className={classNames('transaction-details', { miniature })}>
        {toggle && (
          <Whisper placement="top" speaker={<Tooltip>{miniature ? 'Maximize' : 'Minimize'}</Tooltip>}>
            <button className="toggle-miniature-button" onClick={toggleMiniatureExperience}>
              <IconPlay />
            </button>
          </Whisper>
        )}
        {miniature
          ? MiniatureComponent
          : rows.map((row, idx) => (
              <Whisper
                followCursor
                disabled={row.label === 'Attachments'}
                key={`${row.value}_${idx}`}
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={<Tooltip>{row.value === '-' ? 'No Vendor Name' : row.value}</Tooltip>}
              >
                <div
                  data-testid={`row-details-${row.label.toLowerCase()}`}
                  className={classNames('transaction-details-row', { [row.label.toLowerCase()]: true })}
                >
                  <span className="description">{row.label}</span>
                  <span className="value">{row.value === '-' ? 'No Vendor Name' : row.value}</span>
                </div>
              </Whisper>
            ))}
      </div>
      <div className={classNames('solve-button-wrapper', { miniature })}>
        {isBookkeeperAdmin && transaction?.id && statusLocal() !== 'Closed' && transaction?.canRecategorize && (
          <RecategorizeButton activeTab={statusLocal()} locationId={transaction.locationId} txId={transaction?.id} />
        )}
        {transaction?.id && statusLocal() !== 'Closed' && (
          <MarkAsSolvedButton
            locationId={transaction.locationId}
            activeTab={statusLocal()}
            txId={transaction?.id}
            isSideView={props.isSideView}
          />
        )}
      </div>
    </>
  );
}

export default TransactionDetails;
