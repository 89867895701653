import DashboardCard, { DashboardCardProps } from '../DashboardCard/DashboardCard';
import './DashboardCardList.scss';

type DashboardCardListProps = {
  cards: DashboardCardProps[];
};

const DashboardCardList = ({ cards }: DashboardCardListProps) => {
  return (
    <div className="dashboard-cards-lists-container">
      {cards.map((card, index) => {
        return (
          <div key={index} className="dashboard-cards-lists-container__card">
            <DashboardCard {...card} />
          </div>
        );
      })}
    </div>
  );
};

export default DashboardCardList;
