import { useDashboardReport } from 'hooks/useDashboard';

import { isEmpty } from 'lodash';
import { SummaryCard, SummaryItem } from '@hone-automation/common';

import { useAuthContext } from 'context/useAuthContext';
import { useAskRequestsQuery } from 'hooks/useAskRequestsQuery';
import Dashboard from 'presentation/components/Dashboard';
import KSHeader from 'presentation/components/KSHeader';
import { useEffect } from 'react';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { usePrevious } from 'react-use';

export default function DashboardViewNew(): JSX.Element {
  const { user } = useAuthContext();
  const { currentLocation } = useLocationsStore(state => ({
    currentLocationId: state.currentLocationId,
    currentLocation: state.currentLocation,
  }));
  const meId = user?.uid;
  const { data: requests } = useAskRequestsQuery();
  const {
    dashboardReport,
    activeBussinessMetric,
    timeframe,
    setTimeFrame,
    fetchDashboardReport,
    setActiveBussinessMetric,
  } = useDashboardReport();
  const prevCurrentLocationId = usePrevious(currentLocation?.id);
  const prevTimeframe = usePrevious(timeframe);
  useEffect(() => {
    if (prevTimeframe === 'Current Week') setTimeFrame('Last Week');
    if (
      currentLocation &&
      currentLocation?.id &&
      (prevCurrentLocationId !== currentLocation?.id || prevTimeframe !== timeframe)
    ) {
      setActiveBussinessMetric('Cost of Goods Sold');
      fetchDashboardReport(currentLocation?.id, timeframe);
    }
  }, [
    currentLocation?.id,
    fetchDashboardReport,
    timeframe,
    setActiveBussinessMetric,
    setTimeFrame,
    prevCurrentLocationId,
  ]);

  const plReport: SummaryCard | undefined = dashboardReport?.cards.find(card => card.type === 'Insights');

  const netSales = plReport?.data.items.find(item => item.title === 'Net Sales');
  const expenses = plReport?.data.items.find(item => item.title === activeBussinessMetric);
  const chartItems =
    !isEmpty(netSales) && !isEmpty(expenses) && netSales?.chartItems?.concat(expenses.chartItems as SummaryItem[]);
  return (
    <div className="dashboard-view">
      <KSHeader />
      <Dashboard meId={meId!} requests={requests!} chartData={chartItems as SummaryItem[]} />
    </div>
  );
}
